import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Container,
  Grid,
  Theme,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import careerSiteHeroImage from '../../images/hero/career-site-hero.jpg';
import { WaveUpHero } from '../WaveSVGs/WaveUpHero';
import theme from '../../util/theme';
//import { FCC } from '../../util';

const useStyles = makeStyles((theme: Theme) => ({
  heroLeft: {
    position: 'relative',
    zIndex: 3,
  },

  heroHeader: {
    lineHeight: '1',
    color: '#002D5C',
    fontWeight: 900,
    fontSize: '7rem',
    textTransform: 'uppercase',

    [theme.breakpoints.down('lg')]: {
      fontSize: '6.2rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '5.5rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3.5rem',
    },
  },
  heroSubheader: {
    color: '#2F7FC1',
    lineHeight: '53px',
    fontWeight: 700,
    lineheight: '1',
    fontSize: '3.8rem',
    marginTop: '50px',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '2.625rem',
      marginBottom: '0.25rem',
      marginLeft: '2px',
      marginTop: '0px',
    },
    [theme.breakpoints.down('sm')]: {
      //marginLeft: '0',
    },
  },
  heroBodyContent: {
    lineHeight: 1,
    fontSize: '2.5rem',
    color: 'rgba(34, 31, 31, 0.71)',
    fontWeight: 700,
    textAlign: 'left',
    textTransform: 'uppercase',
    margin: '10px 0 1rem 5px',

    [theme.breakpoints.down('lg')]: {
      fontSize: '2.2rem',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '1.95rem',
      margin: '5px 0 1rem 5px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.375rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  cta: {
    fontSize: '20px',
    marginLeft: '5px',
    color: '#ffffff',
    marginTop: '10px',
    marginBottom: '50px',
    padding: '12px 28px',

    [theme.breakpoints.down('md')]: {
      marginBottom: '0px',
    },
  },
  heroContainer: {
    position: 'relative',
    background: '#F8FBFF',
    padding: '5rem 0 4rem 0',
    overflow: 'hidden',
    maxWidth: '100vw',
    [theme.breakpoints.down('xs')]: {},
  },

  heroRight: {
    [theme.breakpoints.down('sm')]: {
      opacity: '0.25',
    },
  },

  heroCircleSmall: {
    background: '#2A7ABC',
    position: 'absolute',
    right: '750px',
    top: '10%',
    transform: 'translateY(-50%)',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    objectFit: 'cover',

    [theme.breakpoints.down('lg')]: {
      right: '680px',
    },

    [theme.breakpoints.down('md')]: {
      right: '500px',
    },

    [theme.breakpoints.down('sm')]: {
      top: '9%',
      right: '80%',
    },
  },
  heroCircleMedium: {
    background: '#002D5C',
    position: 'absolute',
    right: '490px',
    top: '85%',
    transform: 'translateY(-50%)',
    width: '350px',
    height: '350px',
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.10)',
    [theme.breakpoints.down('lg')]: {
      right: '380px',
    },

    [theme.breakpoints.down('md')]: {
      right: '250PX',
    },
    [theme.breakpoints.down('sm')]: {
      top: '80%',
      right: '100PX',
    },
  },
  heroCircleLarge: {
    background: '#0F95A4',
    position: 'absolute',
    right: '180px',
    top: '42%',
    transform: 'translateY(-50%)',
    width: '550px',
    height: '550px',
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.20)',
    [theme.breakpoints.down('lg')]: {
      right: '80px',
      top: '40%',
    },
    [theme.breakpoints.down('md')]: {
      width: '500px',
      height: '500px',
      top: '44%',
      right: '-30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '550px',
      height: '550px',
      top: '46%',
      right: '-180px',
    },
  },

  heroVideo: {
    position: 'absolute',
    right: '-100px',
    top: '41%',
    transform: 'translateY(-50%)',
    width: '750px',
    height: '750px',
    borderRadius: '50%',
    objectFit: 'cover',
    boxShadow: '0px 6px 20px 0px rgba(0, 0, 0, 0.40)',
    [theme.breakpoints.down('lg')]: {
      right: '-200px',
    },

    [theme.breakpoints.down('md')]: {
      width: '550px',
      height: '550px',
      top: '45%',
      right: '-150px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '470px',
      height: '470px',
      top: '46%',
      right: '-150px',
    },
  },
}));

// export interface HeroProps {
//     title: string | JSX.Element;
//     description?: string | JSX.Element;
// }

//const Hero: FCC<HeroProps> = (props) => {
const Hero = () => {
  const classes = useStyles();
  const lg = useMediaQuery('(max-width: 1280px)');
  const md = useMediaQuery('(max-width: 960px)');
  const sm = useMediaQuery('(max-width: 715px)');
  const xs = useMediaQuery('(max-width: 481px)');
  return (
    <div className={classes.heroContainer}>
      <Container style={{ position: 'inherit' }}>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.heroLeft}
          xs={12}
          md={9}
        >
          <Typography variant="h4" component="h1" className={classes.heroSubheader}>
            Looking for your
          </Typography>
          <Typography variant="h2" component="h2" className={classes.heroHeader}>
            dream job?
          </Typography>
          <Typography variant="body1" role="heading" className={classes.heroBodyContent}>
            You’ve come to the right place!
          </Typography>
          <a href="https://jobs.lever.co/workwave" target="_blank" rel="noreferrer">
            <Button variant="contained" size="large" color="secondary" className={classes.cta}>
              Browse Jobs
            </Button>
          </a>
        </Grid>
      </Container>

      <div className={classes.heroRight}>
        <div className={classes.heroCircleSmall}></div>
        <div className={classes.heroCircleMedium}></div>
        <div className={classes.heroCircleLarge}></div>
        {!md ? (
          <>
            <div
              className={classes.heroVideo}
              style={{
                backgroundColor: '#fff',
                backgroundImage: `url(${careerSiteHeroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
            {/* <video autoPlay loop muted className={classes.heroVideo}>
              <source
                src={
                  'https://media.istockphoto.com/id/941753850/video/multiethnic-team-working-in-modern-trendy-office-happy-young-smiling-creative-millennials-work.mp4?s=mp4-640x640-is&k=20&c=4rP1slhtzhcEocy5_bb6fot40aP_NNc5L9ug37M6E74='
                }
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video> */}
          </>
        ) : (
          <>
            <div
              className={classes.heroVideo}
              style={{
                backgroundColor: '#fff',
                backgroundImage: `url(${careerSiteHeroImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
          </>
        )}
      </div>

      <WaveUpHero height="213" width="100%" fill="white" company home={true} />
    </div>
  );
};

export default Hero;
